// Used to either simiulate a delay on an API call or to randomise
// the speed of a response to prevent timing attacks

import { omit } from 'lodash';
import { NextApiRequest } from 'next';

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Get the error message to display to the user
 * @param internalEmailFailed Whether the internal email failed to send
 * @param userEmailFailed Whether the user email failed to send
 * @returns The error message to display to the user
 */
export const getErrorMessage = (internalEmailFailed: boolean, userEmailFailed: boolean): string => {
  if (internalEmailFailed && userEmailFailed) {
    return 'Internal email failed and user email failed to send.';
  }

  if (internalEmailFailed) {
    return 'Internal email failed to send.';
  }

  if (userEmailFailed) {
    return 'User email failed to send, check your email address is correct.';
  }

  return 'An unknown error has occured.';
};

/**
 * Ensures protected fields are never allowed to be passed through
 */
export const stripProtectedFields = <T>(rawData: any): T => {
  return omit(rawData, ['createdAt', 'updatedAt', 'password']);
};

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const sensitiveKeys = ['password', 'token'] as const; // No need to explicitly list email as it is already covered by isEmailField

const maskEmail = (email: string): string => {
  const [localPart, domain] = email.split('@');
  const maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(Math.max(localPart.length - 2, 0));
  return `${maskedLocalPart}@${domain}`;
};

const isEmail = (value: string): boolean => emailPattern.test(value);

const isEmailField = (key: string, value: string): boolean => key.toLowerCase().includes('email') || isEmail(value);

export const sanitiseSensitiveFields = (data: unknown): unknown => {
  // Handle non-objects
  if (!data || typeof data !== 'object') {
    return data;
  }

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map((item) => {
      if (typeof item === 'string' && isEmail(item)) {
        return maskEmail(item);
      }
      return sanitiseSensitiveFields(item);
    });
  }

  // Handle objects
  return Object.fromEntries(
    Object.entries(data as Record<string, unknown>).map(([key, value]) => {
      // Recursively handle nested objects/arrays
      if (value && typeof value === 'object') {
        return [key, sanitiseSensitiveFields(value)];
      }

      // Handle email fields
      if (typeof value === 'string' && isEmailField(key, value)) {
        return [key, maskEmail(value)];
      }

      // Handle other sensitive fields
      if (sensitiveKeys.includes(key as (typeof sensitiveKeys)[number])) {
        return [key, '******'];
      }

      return [key, value];
    }),
  );
};

// Useful for logging request errors without leaking cookies or headers
export const stringifyRequestForLog = (request: NextApiRequest): string => {
  const { method, body, url, query } = request;
  return JSON.stringify({
    method,
    url,
    body: sanitiseSensitiveFields(body),
    query,
  });
};
