import { format } from 'date-fns';
import { ArrowUp } from 'lucide-react';
import Image from 'next/image';
import { signIn } from 'next-auth/react';
import { useEffect, useState } from 'react';

import { Button } from '@/components/button';
import { Container } from '@/components/container';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Link } from '@/components/link';
import { Logo } from '@/components/logo';
import { Text } from '@/components/text';
import { userNavStyles } from '@/components/user-nav';
import { VisuallyHidden } from '@/components/visually-hidden';
import { SITE_NAME } from '@/lib/constants';
import { FooterNav, footerNav, footerNavSocial, marketingPages } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

const PageFooter = () => {
  // We use date state so that it is only ever rendered on the client to avoid
  // hydration errors around new years
  const [date, setDate] = useState<Date>();
  const {
    base,
    heading,
    main,
    navContainer,
    navLink,
    logo,
    legals,
    legalText,
    iconList,
    buttons,
    text,
    flexContainer,
    legalTextLinks,
    backToTopLink,
    backToTopText,
    buttonSpan,
  } = footerStyles();

  const handleFooterLinkClick = (label: string) => {
    trackUser.conversion({ category: 'FooterLinks', action: `Click${label}` });
  };

  const handleSignIn = () => {
    signIn();

    trackUser.event('Go to: Sign-in', {
      location: 'Nav',
    });
  };

  useEffect(() => {
    setDate(new Date());
  }, []);

  return (
    <footer className={base()}>
      <Container className={main()}>
        <div className="max-w-96">
          <div className={logo()}>
            <Link href="/">
              <Logo variant="light" className="w-[132px]" />
            </Link>
          </div>
          <Text className={text()}>
            At Fluent Cargo, our mission is to create the world&apos;s most comprehensive shipment planning tools for
            those in global trade.
          </Text>
          <ul className={buttons()}>
            <li>
              <Button onClick={() => handleSignIn()} size="small" isRounded>
                <span className={buttonSpan()}>
                  Sign in
                  <Icon name="user" size="16px" className={userNavStyles.slots.userIcon} />
                </span>
              </Button>
            </li>
          </ul>
          <ul className={iconList()}>
            {footerNavSocial.items.map((item, i) => (
              <li key={`footer-nav-item-${i}`}>
                <Link href={item.url} onClick={() => handleFooterLinkClick(item.label)} target="_blank">
                  <Icon name={item.icon} />
                  <VisuallyHidden>{item.label}</VisuallyHidden>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={navContainer()}>
          {Object.entries(footerNav).map(([key, navSection]) => (
            <div key={key}>
              <Heading as="h5" variant="h6" className={heading()}>
                {navSection.heading}
              </Heading>
              <nav>
                {navSection.items.map((item: FooterNav[keyof FooterNav]['items'][number]) => (
                  <Link
                    key={item.url}
                    href={item.url}
                    prefetch={false}
                    className={navLink()}
                    onClick={() => handleFooterLinkClick(item.label)}
                  >
                    {item.label}
                  </Link>
                ))}
              </nav>
            </div>
          ))}
        </div>
      </Container>
      <Container>
        <div className={legals()}>
          <div className={flexContainer()}>
            <Image width={34} height={34} src="/favicon-white.png" alt={SITE_NAME} />
            <Text className={legalText()}>
              Copyright &copy; {date ? format(date, 'Y') : ''} {SITE_NAME}.{' '}
              <span className={legalTextLinks()}>
                <Link href={marketingPages.terms.url}>{marketingPages.terms.label}</Link> /{' '}
                <Link href={marketingPages.privacy.url}>{marketingPages.privacy.label}</Link>
              </span>
            </Text>
          </div>
          <div>
            <a href="#main" className={backToTopLink()}>
              <Text className={backToTopText()}>
                <span className="mr-2">Back to top</span> <ArrowUp size={18} />
              </Text>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

const footerStyles = tv({
  slots: {
    base: 'bg-blue-800',
    heading: 'mb-4 text-baseSm font-medium tracking-[0.1rem] text-lightBlue-500 md:mb-8 lg:text-baseSm',
    main: 'grid gap-12 py-8 md:py-20 md:pb-6 md:pt-12 lg:grid-cols-[1.2fr_3fr]',
    navContainer: 'grid gap-x-4 gap-y-8 text-white sm:grid-cols-2 md:grid-cols-4 md:gap-4',
    navLink: 'decoration-none relative mt-2 block cursor-pointer text-white underline-offset-2 hover:underline',
    legals: 'justify-between border-t border-lightBlue-500 border-opacity-20 py-6 text-white md:flex md:items-center',
    legalText: 'mb-4 ml-3 text-white md:mb-0 md:ml-4 [&_a]:underline-offset-2 hover:[&_a]:underline',
    iconList:
      'mt-6 flex list-none items-center gap-4 p-0 [&_a]:transition-opacity hover:[&_a]:opacity-80 [&_svg]:h-5 [&_svg]:w-auto [&_svg]:text-white',
    logo: '[&_svg]:!w-full',
    buttons: 'mb-2 mt-6 flex w-24 flex-col gap-y-2 lg:mb-16',
    text: 'mb-0 mt-4 leading-6 text-white',
    flexContainer: 'flex items-start md:items-center',
    legalTextLinks: 'max-md:block',
    backToTopLink: 'block text-white underline-offset-2 hover:underline max-md:mt-2 [&_svg]:hover:animate-bounce-y',
    backToTopText: 'mb-0 flex items-center',
    buttonSpan: 'flex items-center justify-between',
  },
});

export { PageFooter };
