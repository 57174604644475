import { addDays, addWeeks, format, startOfDay } from 'date-fns';

import { SimpleDateRange } from '@/components/ui/inputs/advanced-date-picker/utils';
import {
  ID_CANONICAL,
  ISO_DATE_TIME_FORMAT,
  MODE_AIR,
  PORTS_SEARCH_URL,
  ROUTING_SEARCH_URL,
  SCHEDULE_SEARCH_URL,
  SITE_DOMAIN,
} from '@/lib/constants';
import { GenericLinkInterface, LocationInterface, PortDetails, PortInterface, RouteLocationInterface } from '@/types';
import { IdentifierApiInterface, PlaceInterface, SegmentModeType } from '@/types/api-types';
import { replaceSpaceWithPlus } from '@/utils/format/replace-space-with-plus';
import { QUERY_PARAM } from '@/utils/helpers/api-urls/constants';
import { getPreferredIdentifier } from '@/utils/helpers/get-preferred-identifier';
import { getLocationUrlQueryValue } from '@/utils/places';
import { locationsToSearchQueryParams } from '@/utils/route';

/**
 * Creates a consistent Port page Url
 * @param identifiers - list of possible identifiers
 * @param absolute - is absolute url or relative (false by default)
 * @returns - formatted url string
 */
export const createPortPageUrl = (identifiers?: IdentifierApiInterface[], absolute = false): string => {
  const basePath = `${absolute ? SITE_DOMAIN : ''}/ports/`;
  // If an array of indentifiers are available, use them to construct a different url
  // ~/ports/locode/IDTNJ
  if (identifiers?.length) {
    const preferredId = getPreferredIdentifier(identifiers);
    if (preferredId) return `${basePath}${preferredId.type}/${encodeURIComponent(preferredId.value)}`;
  }
  return '';
};

/**
 * Creates a consistent Carrier page Url
 * @param identifiers - list of possible identifiers
 * @param absolute - is absolute url or relative (false by default)
 * @returns - formatted url string
 */
export const createCarrierPageUrl = (identifiers?: IdentifierApiInterface[], absolute = false): string => {
  const basePath = `${absolute ? SITE_DOMAIN : ''}/carriers/`;
  // If an array of indentifiers are available, use them to construct a different url
  // ~/carriers/iata/IDTNJ
  if (identifiers?.length) {
    const preferredId = getPreferredIdentifier(identifiers);
    // Canonicals do not use codes in url
    if (preferredId?.type === ID_CANONICAL) return `${basePath}${encodeURIComponent(preferredId.value)}`;
    // Else specific code in url
    if (preferredId) return `${basePath}${preferredId.type}/${encodeURIComponent(preferredId.value)}`;
  }
  return '';
};

/**
 * Creates a Route page Url Object
 * @param location Page Location (from or to)
 * @param city City Object from store
 * @param direction Outbound or Inbound (from or to)
 * @returns GenericLinkInterface
 */
export const createRoutePageUrlObject = (
  source: LocationInterface,
  target: LocationInterface,
  direction: 'outbound' | 'inbound' = 'outbound',
): GenericLinkInterface => {
  // Inbound
  if (direction === 'inbound') {
    return {
      url: createRoutePageUrl(target.placeId || '', source.placeId),
      value: `${target.name} to ${source.name}`,
    };
  }
  // Outbound (default)
  return {
    url: createRoutePageUrl(source.placeId || '', target.placeId),
    value: `${source.name} to ${target.name}`,
  };
};

/**
 * Create Route Page Url
 * @param fromKey - from canonical
 * @param toKey - to cononical
 * @param absolute - boolean, if should show domain/host
 * @returns url string
 */
export const createRoutePageUrl = (fromKey: string, toKey?: string | null, absolute = false) => {
  const basePath = `${absolute ? SITE_DOMAIN : ''}/routes/`;
  return `${basePath}${fromKey}${toKey ? `/${toKey}` : ``}`;
};

/**
 * Creates a Search Page Url in the required pattern
 * @param from - LocationInterface
 * @param to - LocationInterface
 * @returns string
 */
export const createSearchPageUrl = (
  from: LocationInterface | PortInterface,
  to: LocationInterface | PortInterface,
): string => {
  const searchParams = locationsToSearchQueryParams({ origin: from, destination: to });
  return `${ROUTING_SEARCH_URL}?${new URLSearchParams(searchParams)}`;
};

/**
 * Creates a Search Page Url using keys
 */
export const createSearchPageUrlUsingKeys = (
  fromKey: string | string[] | undefined,
  toKey: string | string[] | undefined,
): string => {
  const searchParams = locationsToSearchQueryParams({
    origin: Array.isArray(fromKey) ? fromKey[0] : fromKey,
    destination: Array.isArray(toKey) ? toKey[0] : toKey,
  });
  return `${ROUTING_SEARCH_URL}?${new URLSearchParams(searchParams)}`;
};

export const createScheduleSearchPageUrl = ({
  origin,
  destination,
  mode,
  dateRange,
  filters,
}: {
  origin: RouteLocationInterface;
  destination: RouteLocationInterface;
  mode: SegmentModeType;
  dateRange?: SimpleDateRange;
  filters?: { [key: string]: any };
}): string => {
  const originString = getLocationUrlQueryValue(origin);
  const destinationString = getLocationUrlQueryValue(destination);
  return createScheduleSearchPageUrlString({ originString, destinationString, mode, dateRange, filters });
};

export const createScheduleSearchPageUrlString = ({
  originString,
  destinationString,
  mode,
  dateRange,
  filters,
}: {
  originString: string;
  destinationString: string;
  mode: SegmentModeType;
  dateRange?: SimpleDateRange;
  filters?: { [key: string]: any };
}): string => {
  let finalDateRange: SimpleDateRange | undefined = dateRange;
  if (!finalDateRange) {
    // Set up a default date range - 3 days in advance for air, 1 week for ocean
    if (mode === MODE_AIR) {
      finalDateRange = { dateFrom: startOfDay(addDays(new Date(), 3)) };
    } else {
      finalDateRange = { dateFrom: startOfDay(addWeeks(new Date(), 1)) };
    }
  }

  const basePath = `${SCHEDULE_SEARCH_URL}?`;

  const queryParams = {
    [QUERY_PARAM.ORIGIN]: originString,
    [QUERY_PARAM.DESTINATION]: destinationString,
    [QUERY_PARAM.MODE]: mode,
    [QUERY_PARAM.DATE_FROM]: finalDateRange.dateFrom ? format(finalDateRange.dateFrom, ISO_DATE_TIME_FORMAT) : '',
    [QUERY_PARAM.DATE_TO]: finalDateRange.dateTo ? format(finalDateRange.dateTo, ISO_DATE_TIME_FORMAT) : '',
    ...(filters ? { [QUERY_PARAM.FILTERS]: JSON.stringify(filters) } : {}),
  };

  const queryString = new URLSearchParams(queryParams);
  return `${basePath}${queryString}`;
};

/**
 * Creates a Port Insights Deep link Url
 * @param portDetails - PortDetails
 * @returns string
 */
export const createPortInsightsDeepLinkUrl = (portDetails: PortDetails | PlaceInterface): string => {
  const basePath = `${PORTS_SEARCH_URL}/?`;
  const portUrlQueryValue = getLocationUrlQueryValue(portDetails);
  return `${basePath}port=${replaceSpaceWithPlus(portUrlQueryValue)}`;
};

/**
 * Creates a Port Insights Deep link Place Url (Cities etc)
 * @param placeName - string
 * @returns string
 */
export const createPortInsightsDeepLinkPlaceUrl = (placeName: string): string => {
  const basePath = `${PORTS_SEARCH_URL}/?`;
  const placeUrlQueryValue = getLocationUrlQueryValue(placeName);
  return `${basePath}place=${replaceSpaceWithPlus(placeUrlQueryValue)}`;
};

/**
 * Checks a nav item URL against the current URL, including
 * query strings to determine if it matches
 */
export const isNavItemActive = ({ asPath, itemUrl }: { asPath: string; itemUrl: string }) => {
  const [basePath, queryString] = asPath.split('?');
  const [itemBasePath, itemQueryString] = itemUrl.split('?');

  if (basePath !== itemBasePath) {
    return false;
  }

  // If itemUrl has a query string, check for partial matches
  if (itemQueryString) {
    const queryParams = new URLSearchParams(queryString);
    const itemQueryParams = new URLSearchParams(itemQueryString);

    // Convert itemQueryParams to an array of key-value pairs
    // and check if every pair matches in queryParams
    const matchesAllParams = Array.from(itemQueryParams.entries()).every(([key, value]) => {
      return queryParams.has(key) && queryParams.get(key)?.includes(value);
    });

    if (!matchesAllParams) {
      return false;
    }
  }

  // If we're here, it means either both base paths and query strings match,
  // or there was no query string to worry about
  return true;
};

export const getPendingTrackingRequestUrl = (id: string) => {
  return `/tracking/pending/${id}`;
};
