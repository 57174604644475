// @TODO: Delete in favour of OmniSearchBlock after deployed and working in DatoCMS

import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import { Block } from '@/components/block';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { AdvancedSearchModal } from '@/components/modules/advanced-search/modal';
import { SearchBar } from '@/components/modules/search/bar';
import { Text } from '@/components/text';
import { routeSearchDestinationState, routeSearchOriginState } from '@/state/routes';
import { advancedSearchFormOpenState, cargoSearchParamsState, searchParamsSelectorState } from '@/state/search';
import { SearchBlockProps } from '@/types/block-types';
import { initialiseUserSearchCount } from '@/utils/helpers';
import { searchStateToQueryParams } from '@/utils/route';
import { tv } from '@/utils/styles';

const SearchBlock = ({ id, isUnderPageHeader, ...props }: SearchBlockProps) => {
  const router = useRouter();
  const origin = useRecoilValue(routeSearchOriginState);
  const destination = useRecoilValue(routeSearchDestinationState);
  const searchParams = useRecoilValue(searchParamsSelectorState);
  const cargoParams = useRecoilValue(cargoSearchParamsState);

  const handleSearch = () => {
    const query = searchStateToQueryParams({ origin, destination, advancedSearchParams: searchParams, cargoParams });
    router.push({ pathname: '/search', query });
  };

  initialiseUserSearchCount();
  const advancedSearchIsOpen = useRecoilValue(advancedSearchFormOpenState);

  const { base, searchContainer, wrapper, icon } = styles({ isUnderPageHeader });

  return (
    <>
      <Block id={id} {...props} className={base()}>
        <div className={searchContainer()}>
          <div className={wrapper()}>
            <div>
              <Heading variant="h2" className="-mt-xs mb-xs">
                <Icon className={icon()} name="directions" color="text.primary" />
                Start planning now
              </Heading>
              <Text variant="secondary">
                Enter an origin and destination and press search to compare transport modes &amp; route options.
              </Text>
            </div>
            <SearchBar
              onSearch={handleSearch}
              searchVariant="homepage"
              buttonLabel="Search routes"
              className="[&_.autocomplete-group_label]:!text-grey-600"
            />
          </div>
        </div>
      </Block>
      {advancedSearchIsOpen && <AdvancedSearchModal onSearch={handleSearch} />}
    </>
  );
};

const styles = tv({
  slots: {
    base: 'relative bg-grey-50 py-0',
    searchContainer: 'relative z-1 flex rounded-lg bg-white shadow-lg xl:-mx-8',
    wrapper: 'flex flex-1 flex-col content-between p-6 lg:p-8',
    icon: 'mr-2 inline-block',
  },
  variants: {
    isUnderPageHeader: {
      true: {
        base: 'py-0 xl:py-0',
        searchContainer: '-mt-20',
      },
    },
  },
});

export { SearchBlock };
