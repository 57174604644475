import { Block } from '@/components/block';
import { BlockHeader } from '@/components/block/header';
import { ButtonGroup } from '@/components/button-group';
import { Image } from '@/components/image';
import { ContentMediaBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ContentMediaBlock = ({
  id,
  heading,
  body,
  children,
  image,
  isReversed = false,
  buttons = [],
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  isImageBackground,
  variant,
  bodySize = 'md',
  ...props
}: ContentMediaBlockProps) => {
  const { base, grid, imageCol, contentCol, fakeCol, overlay } = contentMediaBlockStyles({
    isReversed,
    isImageBackground,
    variant,
  });

  const bodySizeOverride = isImageBackground ? 'lg' : bodySize;

  return (
    <Block id={id} className={base()} noTopPadding={noTopPadding} {...props}>
      <div className={grid()}>
        <div className={imageCol()}>
          <Image
            data={image}
            className="rounded-md"
            objectFit={isImageBackground ? 'cover' : undefined}
            layout={isImageBackground ? 'fill' : 'intrinsic'}
          />
          <span className={overlay()} />
        </div>

        <div className={contentCol()}>
          <div>
            {children || (
              <>
                <BlockHeader
                  heading={heading}
                  headingVariant={headingVariant}
                  preHeadingVariant={preHeadingVariant}
                  preHeading={preHeading}
                  body={body}
                  variant={variant}
                  bodySize={bodySizeOverride}
                />

                {buttons.length ? <ButtonGroup buttons={buttons} className="mt-8 justify-start" size="lg" /> : null}
              </>
            )}
          </div>
        </div>

        {/* Fake column to make the grid work when the image is a background image */}
        <div className={fakeCol()} />
      </div>
    </Block>
  );
};

const contentMediaBlockStyles = tv({
  slots: {
    base: 'relative pb-12 pt-12 @container xl:pb-16 xl:pt-16',
    grid: 'grid-class grid-auto-rows-min relative grid justify-center gap-8 @4xl:lg:grid-cols-2 @4xl:lg:grid-rows-none @4xl:lg:justify-normal @4xl:lg:gap-8',
    imageCol: 'mx-auto w-full md:max-md:w-10/12',
    contentCol:
      'content-col relative z-2 mx-auto flex w-full justify-center md:items-center md:max-md:w-10/12 lg:order-first lg:justify-normal lg:pr-8 xl:pr-16',
    fakeCol: 'fake-col hidden',
    overlay: 'overlay absolute inset-0 hidden bg-black/30',
  },
  variants: {
    variant: {
      light: {},
      grey: {},
      dark: {},
    },
    isImageBackground: {
      true: {
        grid: 'p-8 @4xl:lg:grid-cols-[minmax(320px,420px)_auto]',
        contentCol: 'rounded-sm bg-white p-8 md:max-lg:w-full',
        imageCol: 'absolute z-1 h-full w-full md:max-lg:w-full',
      },
    },
    isReversed: {
      true: {
        contentCol: '@4xl:lg:order-last @4xl:lg:pl-8 @4xl:xl:pl-16 @4xl:xl:pr-5',
      },
    },
  },
  compoundVariants: [
    {
      isImageBackground: true,
      isReversed: true,
      class: 'xl:[&_.content-col]:px-8 lg:[&_.fake-col]:block lg:[&_.grid-class]:grid-cols-[auto_minmax(320px,420px)]',
    },
    {
      isImageBackground: true,
      variant: 'dark',
      class: '[&_.content-col]:bg-transparent [&_.overlay]:block',
    },
  ],
});

export { ContentMediaBlock };
