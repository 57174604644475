import { isFunction } from 'lodash';
import React from 'react';

import { Icon } from '@/components/icon';
import { Tooltip } from '@/components/tooltip';
import { tv } from '@/utils/styles';

export interface RadioButtonProps {
  children: React.ReactNode;
  value: string;
  name?: string;
  variant?: 'button' | 'chunky-button';
  checked?: boolean;
  onChange?: (val: string) => void;
  tooltipContent?: React.ReactNode | string; // Untested with anything other than the chunky-button variant
}

const Radio = ({ children, value, name, variant, checked = false, onChange, tooltipContent }: RadioButtonProps) => {
  const handleOnChange = (): void => {
    if (isFunction(onChange)) onChange(value);
  };

  const radioId = `${value}-radiobutton`;

  const { base, inner, input, radioButton, dot, tooltipTrigger } = radioButtonStyles({ variant });

  return (
    <label className={base()} htmlFor={radioId}>
      <div className={inner()}>
        <input type="radio" id={radioId} name={name} checked={checked} onChange={handleOnChange} className={input()} />

        <div className={radioButton()}>
          <span className={dot()} />
          <span>{children}</span>
        </div>
      </div>
      {tooltipContent && (
        <div className={tooltipTrigger()}>
          <Tooltip content={tooltipContent} variant="dark" maxWidth="260px">
            <span>
              <Icon name="question-mark-circled" size={3} />
            </span>
          </Tooltip>
        </div>
      )}
    </label>
  );
};

const radioButtonStyles = tv({
  slots: {
    base: 'group block cursor-pointer text-baseSm leading-none',
    inner: 'relative flex h-full items-center',
    input: 'peer/radio-button-input absolute -left-[9999px]',
    radioButton:
      'radio-button flex h-full w-full items-center transition-all duration-100 ease-in-out hover:border-grey-300 peer-checked/radio-button-input:[&_.dot]:border-[5px] peer-checked/radio-button-input:[&_.dot]:border-lightBlue-500',
    dot: 'dot mr-1 block h-4 w-4 cursor-pointer rounded-full border-2 border-grey-300 bg-white transition-all duration-100 ease-in-out group-hover:border-[5px] group-hover:border-grey-400',
    tooltipTrigger: 'absolute right-2 top-2 z-2 cursor-pointer',
  },
  variants: {
    variant: {
      button: {
        radioButton: 'rounded border border-grey-200 bg-grey-100 p-2 text-text-primary',
      },
      'chunky-button': {
        base: 'h-full',
        radioButton:
          'rounded-md border border-grey-300 bg-white p-4 text-text-primary shadow-softer hover:border-grey-400 peer-checked/radio-button-input:border-lightBlue-500 peer-checked/radio-button-input:bg-brandSoftBlue peer-checked/radio-button-input:shadow-[0_0_0_1px_theme(colors.lightBlue.500)]',
        dot: 'hidden',
      },
    },
  },
});

export { Radio };
