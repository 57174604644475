/**
 * When using with CMS data, pass the 'data' prop
 * (which will contain responsiveImage etc), otherwise just
 * pass normal image attributes, src, width etc
 */

import { endsWith, omit } from 'lodash';
import { CSSProperties } from 'react';
import { Image as DatoImage } from 'react-datocms';
import { ReactSVG } from 'react-svg';

import { BlockImage } from '@/types/block-types';
import { tv } from '@/utils/styles';

// You can use these props when not using CMS data
interface PlainImageInterface {
  src?: string;
  alt?: string;
  width?: string;
  height?: string;
}

interface Props extends PlainImageInterface {
  svgFilter?: string;
  data?: BlockImage;
  layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill';
  objectFit?: CSSProperties['objectFit'];
  objectPosition?: CSSProperties['objectPosition'];
  className?: string;
}

const Image = ({ src, alt = 'image', width, height, svgFilter, data, className, ...props }: Props) => {
  const renderImageComponent = () => {
    // If it's not an image from Dato
    if (!data?.responsiveImage) {
      // Check if it's an svg and output the raw SVG code
      if (endsWith(src, '.svg')) {
        return <ReactSVG src={src || ''} width={width} height={height} data-testid="inline-svg-image" {...props} />;
      }

      // Otherwise just render a plain image element
      return (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          style={{ width, height }}
          className={className}
          data-testid="plain-image"
          {...props}
        />
      );
    }

    // Add a background colour for loading
    const dataWithBgColor = { ...data.responsiveImage, bgColor: '#F6F6F6' };

    // Then render the Dato image
    return <DatoImage data={dataWithBgColor} className={className} {...props} />;
  };

  const { base } = styles();

  if (!src && !data) {
    return null;
  }
  const svgFilterStyles = { '--svg-filter': svgFilter } as React.CSSProperties;

  return (
    <div
      style={{
        ...svgFilterStyles,
        width,
        height,
      }}
      className={base({ className })}
      // Prevent any of the Dato Image props from being passed to the DOM
      {...omit(props, ['layout', 'objectFit', 'objectPosition'])}
    >
      {renderImageComponent()}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'overflow-hidden [&_svg]:[filter:--svg-filter]',
  },
});

export { Image };
