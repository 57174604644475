import { useRouter } from 'next/router';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Button } from '@/components/button';
import { LocationAutocomplete } from '@/components/location-autocomplete';
import { locationAutocompleteValueState, portsSearchParamsState } from '@/state/ports';
import { PlaceInterface } from '@/types/api-types';
import { createPortInsightsDeepLinkPlaceUrl } from '@/utils/helpers/page-urls';
import { tv } from '@/utils/styles';

const PortsSearchAutocomplete = () => {
  const router = useRouter();
  const [searchParams, setSearchParams] = useRecoilState(portsSearchParamsState);
  const setLocationAutocompleteValue = useSetRecoilState(locationAutocompleteValueState);

  const [isLoading, setIsLoading] = useState(false);
  const [canSearch, setCanSearch] = useState(false);

  const handleSelectLocation = (location?: PlaceInterface) => {
    if (!location) return;

    setSearchParams((prev) => ({
      ...prev,
      origin: location,
    }));

    setLocationAutocompleteValue(location.name);

    setCanSearch(true);
  };

  const handleSearch = () => {
    const { name } = searchParams.origin || {};

    setIsLoading(true);

    if (!name) return;

    router.push(createPortInsightsDeepLinkPlaceUrl(name));
  };

  const { base, autocomplete, searchButton } = styles();

  return (
    <div className={base()}>
      <LocationAutocomplete
        name="ports"
        label="Find ports near"
        placeholder="Search by port name or place"
        onSelect={handleSelectLocation}
        selectedLocation={searchParams.origin}
        className={autocomplete()}
      />
      <Button
        iconLeft="search"
        isLoading={isLoading}
        disabled={!canSearch}
        onClick={handleSearch}
        className={searchButton()}
        data-cy="port-insights-search-button"
      >
        Search
      </Button>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex max-md:flex-col',
    autocomplete: 'max-md:!mb-4 md:[&_label+div]:w-96 [&_label]:text-white',
    searchButton:
      'h-[--input-height] self-start pl-base pr-base text-baseSm font-normal tracking-[0.02rem] max-sm:w-full sm:pl-base sm:pr-base md:ml-2 md:self-end',
  },
});

export { PortsSearchAutocomplete };
