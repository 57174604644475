import { identity, pickBy, sum, values } from 'lodash';
import { atom, selector } from 'recoil';

import { DEFAULT_AIRPORT_COUNT, DEFAULT_SEAPORT_COUNT } from '@/lib/constants';
import { CargoOption, DeveloperSearchQueryInterface, RoutesLayout, SearchQueryInterface } from '@/types';
import { LocationAutocompleteIdInterface } from '@/types/context-types';
import { DEFAULT_ROUTES_LAYOUT } from '@/utils/layout';

export const DEFAULT_SEARCH_PARAMS: SearchQueryInterface = {
  airCarriers: [],
  oceanCarriers: [],
  avoid: [],
  via: [],
  aircraft: [],
  aircraftType: [],
};

export const DEFAULT_CARGO_SEARCH_PARAMS: CargoOption = {};

export const DEFAULT_DEVELOPER_SEARCH_PARAMS: DeveloperSearchQueryInterface = {
  airports: DEFAULT_AIRPORT_COUNT,
  seaports: DEFAULT_SEAPORT_COUNT,
  maxRailDistance: 120,
  maxRailDistanceRatio: 1.5,
};

export const hasSearchedState = atom<boolean>({
  key: 'hasSearchedState',
  default: false,
});

export const isSearchingRoutesState = atom<boolean>({
  key: 'isSearchingRoutesState',
  default: false,
});

export const isSearchingLocationAutocompleteState = atom<LocationAutocompleteIdInterface | null>({
  key: 'isSearchingLocationAutocompleteState',
  default: null,
});

export const errorMessageState = atom<string>({
  key: 'errorMessageState',
  default: '',
});

export const newSearchIsNeededState = atom<boolean>({
  key: 'newSearchIsNeededState',
  default: false,
});

export const searchLimitReachedState = atom<boolean>({
  key: 'searchLimitReachedState',
  default: false,
});

export const searchResultsLayoutState = atom<RoutesLayout>({
  key: 'searchResultsLayoutState',
  default: DEFAULT_ROUTES_LAYOUT,
});

// Not to be used directly, searchParamsSelectorState instead
const searchParamsState = atom<SearchQueryInterface>({
  key: 'searchParamsState',
  default: DEFAULT_SEARCH_PARAMS,
});

export const developerSearchParamsState = atom<DeveloperSearchQueryInterface>({
  key: 'developerSearchParamsState',
  default: DEFAULT_DEVELOPER_SEARCH_PARAMS,
});

// Store the "active" search params so we can compare them later.
// We set these when a search is performed as this is the only time
// when we need to worry about them being different so we can
// tell the user that a new search is needed.
export const activeSearchParamsState = atom<SearchQueryInterface>({
  key: 'activeSearchParamsState',
  default: DEFAULT_SEARCH_PARAMS,
});

export const searchParamsSelectorState = selector<SearchQueryInterface>({
  key: 'searchParamsSelectorState',
  get: ({ get }) => {
    const searchParams = get(searchParamsState);
    return searchParams;
  },
  set: ({ get, set }, data) => {
    const searchParams = get(searchParamsState);
    const updatedSearchParams = { ...searchParams, ...data };

    set(searchParamsState, updatedSearchParams);
  },
});

// Advanced search panel
// -----------------------------

// Advanced search is open
export const advancedSearchFormOpenState = atom<boolean>({
  key: 'advancedSearchFormOpenState',
  default: false,
});

// Ensures we always open to the last panel used
export const advancedSearchActivePanelState = atom<string>({
  key: 'advancedSearchActivePanelState',
  default: 'oceanCarriers',
});

// How many extra/advanced search params are active
export const activeSearchParamsCountState = selector<Record<string, number>>({
  key: 'activeSearchParamsCountState',
  get: ({ get }) => {
    const searchParams = get(searchParamsState);
    const { airCarriers, aircraft, aircraftType, avoid, via, oceanCarriers } = searchParams;

    const counts = {
      airCarriers: airCarriers?.length || 0,
      aircraftType: (aircraft?.length || 0) + (aircraftType?.length || 0),
      avoid: avoid?.length || 0,
      via: via?.length || 0,
      oceanCarriers: oceanCarriers?.length || 0,
    };
    // Keep only properties with a value
    const ommitedCounts = pickBy(counts, identity);

    // Return total, as well as individual counts
    return {
      total: sum(values(ommitedCounts)),
      ...ommitedCounts,
    };
  },
});

// Helper to reset just extra/advanced search params
export const resetAdvancedSearchParamsState = selector({
  key: 'resetAdvancedSearchParamsState',
  get: () => null,
  set: ({ get, set }) => {
    const searchParams = get(searchParamsState);
    // Update Params state
    set(searchParamsState, {
      ...searchParams,
      airCarriers: DEFAULT_SEARCH_PARAMS.airCarriers,
      oceanCarriers: DEFAULT_SEARCH_PARAMS.oceanCarriers,
      aircraft: DEFAULT_SEARCH_PARAMS.aircraft,
      aircraftType: DEFAULT_SEARCH_PARAMS.aircraftType,
      avoid: DEFAULT_SEARCH_PARAMS.avoid,
      via: DEFAULT_SEARCH_PARAMS.via,
    });
  },
});

// Cargo search panel
// -----------------------------

export const cargoSearchParamsState = atom<CargoOption | undefined>({
  key: 'cargoSearchParamsState',
  default: undefined,
});

// Cargo search is open
export const cargoSearchFormOpenState = atom<boolean>({
  key: 'cargoSearchFormOpenState',
  default: false,
});
