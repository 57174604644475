import { useRecoilState, useRecoilValue } from 'recoil';

import { Icon } from '@/components/icon';
import { PromoText } from '@/components/promo-text';
import { useAdvancedSearch } from '@/hooks/use-advanced-search';
import { activeSearchParamsCountState, advancedSearchFormOpenState } from '@/state/search';
import { colors } from '@/theme/constants';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

const AdvancedSearchTrigger = ({ promoTextVariant }: { promoTextVariant?: 'white' }) => {
  const [isOpen, setIsOpen] = useRecoilState(advancedSearchFormOpenState);
  const activeSearchParamsCount = useRecoilValue(activeSearchParamsCountState);
  const { total: selectionCount } = activeSearchParamsCount;
  const { hasAccess, isInTrial } = useAdvancedSearch();

  const handleClick = () => {
    setIsOpen(!isOpen);

    // Analytics Event
    if (!isOpen) {
      trackUser.event('Advanced Search: Open');
    }
  };

  const { triggerButton, iconWrapper, actionIcon, selectionNum, trialMessage } = styles({
    isActive: isOpen,
    hasAccess,
  });

  return (
    <button type="button" className={triggerButton()} onClick={() => handleClick()} id="advanced-search-trigger">
      <span className={iconWrapper()}>
        {selectionCount > 0 && <div className={selectionNum()}>{selectionCount}</div>}
        {!hasAccess && <Icon name="lock" size="base" color={`${colors.states.locked} !important`} />}
        {hasAccess && <Icon name="rules" size="base" color="lightBlue.600" />}
      </span>
      Filters
      <Icon
        name="down"
        size="base"
        transform={isOpen ? 'rotate(-180deg)' : undefined}
        className={actionIcon({ className: 'mr-0' })}
      />
      {hasAccess && isInTrial && (
        <PromoText
          parent={<span className={trialMessage()} />}
          label="Try it out"
          offset={{ crossAxis: -35, mainAxis: 4 }}
          variant={promoTextVariant}
          className="max-md:hidden"
        />
      )}
    </button>
  );
};

const styles = tv({
  slots: {
    iconWrapper: 'relative -ml-1 mr-2 grid size-6 place-items-center rounded-full bg-lightBlue-50',
    actionIcon: 'ml-2',
    triggerButton:
      'static inline-flex h-[var(--input-height)] cursor-pointer items-center justify-between rounded-md border border-solid border-grey-300 bg-white px-3 text-baseSm shadow-softer hover:border-grey-400 disabled:cursor-default disabled:opacity-60 disabled:hover:border-grey-300 [&_svg]:fill-text-primary',
    trialMessage: 'absolute left-0 top-0 block h-4 w-full',
    selectionNum:
      'absolute -right-1 -top-[3px] z-10 grid h-[14px] min-w-[14px] place-items-center rounded-full border border-white bg-lightBlue-500 px-1 text-[8px] text-white',
  },
  variants: {
    isActive: {
      true: {
        triggerButton: '!border-grey-700',
      },
    },
    hasAccess: {
      false: {
        iconWrapper: 'bg-yellowMid/20',
      },
    },
  },
});

export { AdvancedSearchTrigger };
