import { CircleX } from 'lucide-react';

import { Block } from '@/components/block';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Image } from '@/components/image';
import { RichText } from '@/components/rich-text';
import { ProblemsSolutionsBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const ProblemsSolutionsBlock = ({
  id,
  problemsPreHeading,
  problemsHeading,
  problemsBody,
  solutionsPreHeading,
  solutionsHeading,
  solutionsBody,
  noTopPadding = false,
}: ProblemsSolutionsBlockProps) => {
  const { base, grid, item, header, preHeadingStyles, icon, richText } = styles();

  return (
    <Block id={id} className={base()} noTopPadding={noTopPadding}>
      <div className={grid()}>
        <article className={item({ variant: 'negative' })}>
          <header className={header()}>
            <CircleX className={icon({ className: 'text-red-500' })} size={64} />
            <Heading variant="h4" as="h2" className={preHeadingStyles()}>
              {problemsPreHeading}
            </Heading>
            <Image src="/assets/problem-computer.svg" alt="Problem" />
          </header>
          <Heading variant="h2" as="h3">
            {problemsHeading}
          </Heading>
          <RichText className={richText()}>{problemsBody}</RichText>
        </article>
        <article className={item({ variant: 'positive' })}>
          <header className={header()}>
            <Icon name="logo-icon-circled" size="xxl" className={icon()} />
            <Heading variant="h4" as="h2" className={preHeadingStyles()}>
              {solutionsPreHeading}
            </Heading>
            <Image src="/assets/solution-computer.svg" alt="Solution" />
          </header>
          <Heading variant="h2" as="h3">
            {solutionsHeading}
          </Heading>
          <RichText className={richText()}>{solutionsBody}</RichText>
        </article>
      </div>
    </Block>
  );
};

const styles = tv({
  slots: {
    base: '[&_ul_li]:leading-snug',
    grid: 'grid-class grid-auto-rows-min relative grid justify-center gap-8 lg:grid-cols-2 lg:grid-rows-none',
    header: 'flex flex-col items-center',
    icon: 'mb-2',
    preHeadingStyles: 'mb-0 text-base font-semibold uppercase',
    item: 'flex flex-col rounded-b-lg rounded-t border-t-8 p-6 text-brandDarkBlue lg:p-8',
    richText:
      'leading-normal *:text-brandDarkBlue lg:text-baseLg [&_h4]:mb-3 [&_h4]:uppercase [&_li]:marker:text-brandDarkBlue/40',
  },
  variants: {
    variant: {
      negative: {
        item: 'border-red-500 bg-grey-100',
      },
      positive: {
        item: 'border-green-500 bg-brandSoftBlue',
      },
    },
  },
});

export { ProblemsSolutionsBlock };
