import { HeadingVariants, LargeHeadingVariants } from '@/types/block-types';
import { tv } from '@/utils/styles';

// Define a map of heading variants to their corresponding HTML element
const elementMap = {
  d1: 'h1',
  d2: 'h1',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

export interface HeadingProps {
  as?: React.ElementType;
  textTransform?: 'uppercase';
  variant?: LargeHeadingVariants | HeadingVariants;
  children?: React.ReactNode | React.ReactNode[];
}

type ExtendedProps = HeadingProps & React.HTMLAttributes<HTMLHeadingElement>;

const Heading = ({ as, variant = 'h2', className, ...props }: ExtendedProps) => {
  const Element = as || elementMap[variant];

  return (
    <Element className={headingStyles({ variant, className })} {...(props as React.HTMLAttributes<HTMLElement>)} />
  );
};

export const headingStyles = tv({
  variants: {
    variant: {
      h1: 'mb-4 mt-0 text-md font-semibold leading-snug sm:text-lg md:leading-tight lg:text-xl lg:tracking-[.01em]',
      h2: 'mb-4 mt-0 text-md font-medium leading-tight lg:text-lg',
      h3: 'mb-4 mt-0 text-md font-medium leading-snug',
      h4: 'mb-2 mt-0 text-baseLg font-medium leading-snug',
      h5: 'mb-4 mt-0 text-baseSm font-semibold leading-snug',
      h6: 'mt-0 text-sm font-semibold uppercase leading-snug',
      d1: 'mb-5 mt-0 text-xl font-semibold leading-ultraTight tracking-[.02em] md:text-xxl lg:text-xxxl',
      d2: 'mb-5 mt-0 text-lg font-semibold leading-ultraTight tracking-[.02em] md:text-xl lg:text-xxl',
    },
  },
});

export { Heading };
