import { isEmpty } from 'lodash';
import { useSetRecoilState } from 'recoil';

import { Block } from '@/components/block';
import { PostCard } from '@/components/cards/post-card';
import { Pagination } from '@/components/pagination';
import { POST_TYPES } from '@/lib/constants';
import { lastVisitedPageState } from '@/state/posts';
import { PostsBlockProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const PostsBlock = ({
  id,
  heading,
  body,
  headingVariant = 'h1',
  preHeading,
  preHeadingVariant = 'uppercase',
  noTopPadding = false,
  posts,
  showPagination = false,
  itemsPerPage = 10,
  postType = POST_TYPES.BLOG,
  totalPosts = 0,
  currentPage = 1,
  className,
  ...props
}: PostsBlockProps) => {
  const setLastVisitedPage = useSetRecoilState(lastVisitedPageState);

  if (isEmpty(posts)) return null;

  const handlePageChange = (page: number) => {
    setLastVisitedPage(page);
  };

  const getHref = (page: number) => {
    return page === 1 ? `/${postType}` : `/${postType}/page/${page}`;
  };

  const { base } = styles({ variant: postType });

  return (
    <Block
      id={id}
      heading={heading}
      body={body}
      headingVariant={headingVariant}
      preHeading={preHeading}
      preHeadingVariant={preHeadingVariant}
      noTopPadding={noTopPadding}
      className={className}
      {...props}
    >
      <ul className={base()}>
        {posts.map((post) => (
          <li key={post.id}>
            <PostCard post={post} variant={postType} />
          </li>
        ))}
      </ul>

      {showPagination && (
        <Pagination
          totalCount={totalPosts}
          perPage={itemsPerPage}
          pageNumber={currentPage}
          handlePageChange={handlePageChange}
          getHref={getHref}
        />
      )}
    </Block>
  );
};

const styles = tv({
  slots: {
    base: 'grid [&_a]:no-underline',
  },
  variants: {
    variant: {
      [POST_TYPES.BLOG]: {
        base: 'grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-12',
      },
      [POST_TYPES.PRESS]: {
        base: 'grid-rows-1 gap-none md:grid-cols-none lg:grid-cols-none [&_li]:-mb-px',
      },
      [POST_TYPES.CASE_STUDIES]: {
        base: 'grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-12',
      },
    },
  },
});

export { PostsBlock };
