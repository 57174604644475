/**
 * Uses Radix UI's Toast component as a base with these custom changes:
 * - Framer handles the layout of multiple toasts so they are all
 *   aware of each other and animate nicely
 * - As a result of this, we need to manually control the animations using
 *   Framer, particularly replacing the Radix swipe controls with
 *   the Framer drag controls
 */

import * as ToastPrimitive from '@radix-ui/react-toast';
import { motion, PanInfo, useAnimationControls } from 'framer-motion';
import { find, isFunction } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { VisuallyHidden } from '@/components/visually-hidden';
import { useNotifications } from '@/hooks/use-notifications';
import { notificationsState } from '@/state/notifications';
import { space } from '@/theme/constants/space';
import { tv } from '@/utils/styles';

export const TOAST_VARIANTS = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
} as const;

type Variant = (typeof TOAST_VARIANTS)[keyof typeof TOAST_VARIANTS];

interface Props {
  id: number;
  reverseIndex: number; // So the shadows don't overlap the subsequent items
  duration?: number;
  variant?: Variant;
  confirmButtonText?: string;
  icon?: string;
}

const HIDE_DELAY_MS = 150;

const icons: Record<Variant, string> = {
  info: 'info-circle',
  success: 'check-circle',
  warning: 'warning-outline',
  error: 'error-circle',
};

const Toast = ({
  id,
  reverseIndex,
  duration = 5000,
  variant = TOAST_VARIANTS.INFO,
  confirmButtonText = 'Confirm',
  icon,
}: Props) => {
  const { removeNotification } = useNotifications();
  const notifications = useRecoilValue(notificationsState);
  const controls = useAnimationControls();
  const router = useRouter();
  const iconFromTheme = icons[variant];

  const { heading, description, onConfirm, type, href } = find(notifications, { id }) || {};

  const handleConfirm = (() => {
    if (isFunction(onConfirm) && href) {
      return () => {
        onConfirm();
        router.push(href);
      };
    }

    if (isFunction(onConfirm)) {
      return onConfirm;
    }
    if (href) {
      return () => router.push(href);
    }
    return undefined;
  })();

  useEffect(() => {
    // Move the item into place when it first appears. This is
    // used as we're controlling the animation manually
    controls.start({ x: 0, opacity: 1, transition: { ease: 'easeOut' } });
  });

  const handleRemoveNotification = () => {
    setTimeout(() => {
      // Give it a moment to fade out
      removeNotification(id);
    }, HIDE_DELAY_MS);
  };

  const handleDragEnd = async (_: Event, info: PanInfo) => {
    const offset = info.offset.x;
    const velocity = info.velocity.x;

    // If you move it more than 200px or swipe it fast enough, close it
    if (offset > 200 || velocity > 400) {
      await controls.start({ x: `calc(100% + ${space.base})`, transition: { duration: 0.2 } });
      handleRemoveNotification();
    } else {
      controls.start({ x: 0, opacity: 1, transition: { duration: 0.2 } });
    }
  };

  const handleOpenChange = () => {
    // This is only used when the toast is closed by clicking the close button
    // We're controlling the swipe animation (and close) manually
    const HIDE_DELAY_MS_IN_SECONDS = HIDE_DELAY_MS / 1000;

    // If the toast is still in the list, hide it
    if (find(notifications, { id })) {
      controls.start({ x: 5, y: 0, opacity: 0, transition: { duration: HIDE_DELAY_MS_IN_SECONDS } });
      handleRemoveNotification();
    }
  };

  const { base, iconWrapper, content, closeButton, actions, confirmButton } = styles({ variant });

  return (
    <ToastPrimitive.Root
      onOpenChange={handleOpenChange}
      asChild
      forceMount
      style={{ zIndex: reverseIndex || undefined }}
      type={type}
      duration={duration}
    >
      <motion.li
        className={base()}
        initial={{
          y: 0,
          x: '100%',
          opacity: 0,
        }}
        animate={controls}
        layout="position"
        drag="x"
        dragDirectionLock
        dragConstraints={{ left: 0 }}
        onDragEnd={handleDragEnd}
      >
        <div className={iconWrapper()}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, delay: 0.18, type: 'spring', stiffness: 500, damping: 30 }}
          >
            {(iconFromTheme || icon) && <Icon name={icon || iconFromTheme} size="lg" />}
          </motion.div>
        </div>
        <div className={content()}>
          {heading && (
            <ToastPrimitive.Title asChild>
              <Heading variant="h5" className="mb-2">
                {heading}
              </Heading>
            </ToastPrimitive.Title>
          )}
          <ToastPrimitive.Description asChild>
            <Text variant="secondary" dangerouslySetInnerHTML={{ __html: description || '' }} className="mb-0" />
          </ToastPrimitive.Description>
          {handleConfirm && (
            <ToastPrimitive.Action asChild altText={confirmButtonText}>
              <Button className={confirmButton()} size="small" onClick={handleConfirm} variant="secondaryLight">
                {confirmButtonText}
              </Button>
            </ToastPrimitive.Action>
          )}
        </div>
        <div className={actions()}>
          <ToastPrimitive.Close asChild aria-label="Close">
            <button className={closeButton()} type="button">
              <Icon name="close" size="md" />
              <VisuallyHidden>Dismiss</VisuallyHidden>
            </button>
          </ToastPrimitive.Close>
        </div>
      </motion.li>
    </ToastPrimitive.Root>
  );
};

const styles = tv({
  slots: {
    base: "pointer-events-auto relative grid min-h-20 w-52 grid-cols-[max-content_auto_max-content] items-stretch gap-x-4 rounded-md border border-grey-100 bg-white p-2 shadow-xl [grid-template-areas:'icon_content_actions'] md:w-[400px] md:p-3 [&_p]:last:mb-0",
    iconWrapper:
      "relative flex items-center justify-center pl-4 [grid-area:icon] before:absolute before:inset-y-0 before:left-0 before:w-[5px] before:rounded-lg before:content-['']",
    content: 'flex flex-col self-center [grid-area:content]',
    actions: 'flex flex-col items-end [grid-area:actions]',
    closeButton: 'mb-4 [&_svg]:fill-grey-500 hover:[&_svg]:fill-grey-700',
    confirmButton: 'mt-3 self-start',
  },
  variants: {
    variant: {
      info: {
        iconWrapper: 'before:bg-grey-300',
      },
      success: {
        iconWrapper: 'before:bg-states-success-borderColor [&_svg]:fill-states-success-borderColor',
      },
      warning: {
        iconWrapper: 'before:bg-states-warning-vibrant [&_svg]:fill-states-warning-vibrant',
      },
      error: {
        iconWrapper: 'before:bg-forms-states-error [&_svg]:fill-forms-states-error',
      },
    },
  },
});

export { Toast };
