import { isNil } from 'lodash';

import { cn } from '@/utils/styles';

interface Props {
  isDatoStructuredText?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

const RichText = ({ children = null, isDatoStructuredText, className, ...props }: Props) => {
  if (isNil(children)) {
    return null;
  }

  // Custom class names for each rich text element,
  // defined in the global styles
  const classNames = cn(
    'rich-text-h1:rich-text-h1 rich-text-h2:rich-text-h2 rich-text-h3:rich-text-h3 rich-text-h4:rich-text-h4 rich-text-h5:rich-text-h5 rich-text-h6:rich-text-h6 rich-text-a:rich-text-a rich-text-blockquote:rich-text-blockquote rich-text-ul:rich-text-ul rich-text-ol:rich-text-ol rich-text rich-text-table:border-collapse rich-text-th:border-solid rich-text-td:border-solid',
    className,
  );

  if (isDatoStructuredText) {
    return (
      <article className={classNames} {...props}>
        {children}
      </article>
    );
  }

  // eslint-disable-next-line react/no-danger
  return <article className={classNames} dangerouslySetInnerHTML={{ __html: children.toString() }} {...props} />;
};

export { RichText };
