import { map } from 'lodash';

import { Button } from '@/components/button';
import { Heading } from '@/components/heading';
import { Icon } from '@/components/icon';
import { Text } from '@/components/text';
import { VisuallyHidden } from '@/components/visually-hidden';
import { CREATE_ACCOUNT_QUERY_STRING, SHOULD_CREATE_ACCOUNT, SIGN_IN_PAGE_URL } from '@/lib/constants';
import { PricingTiersBlockProps, PricingTiersFeatureTierInterface } from '@/types/block-types';
import { cn, tv } from '@/utils/styles';

const PricingTiers = ({ id, featureCategories, ...props }: PricingTiersBlockProps) => {
  const renderFeatureCell = (feature: PricingTiersFeatureTierInterface) => {
    const { icon } = styles();

    if (feature.text) {
      const isBetaText: boolean = feature.text.includes('Included while in Beta');

      return (
        <Text
          variant={!isBetaText ? 'secondary' : 'sm'}
          className={cn('m-0', {
            'text-grey-400': isBetaText,
            'text-text-primary': !isBetaText,
          })}
        >
          {feature.text}
        </Text>
      );
    }

    if (feature.isIncluded) {
      return <Icon name="check" color="green.500" className={icon()} />;
    }

    return <Icon name="close" color="grey.300" size="base" className={icon()} />;
  };

  const { wrapper, tableWrapper, table, tableHead, tableBody, tableFoot, cost } = styles();

  return (
    <div className={wrapper()} {...props}>
      <div className={tableWrapper()}>
        <table className={table()}>
          <caption>
            <VisuallyHidden>Pricing tiers available for Fluent Cargo software</VisuallyHidden>
          </caption>
          <thead className={tableHead()}>
            <tr>
              <th>
                <VisuallyHidden>Spacer</VisuallyHidden>
              </th>
              <th scope="col">
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Basic
                </Heading>
                <Text variant="sm" className="leading-snug">
                  An introduction to
                  <br /> Fluent Cargo
                </Text>
                <Text className={cost({ className: 'bg-yellow text-baseSm' })}>Free to use</Text>
              </th>
              <th scope="col">
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Starter
                </Heading>
                <Text variant="sm" className="leading-snug">
                  Starter account with
                  <br /> most features
                </Text>
                <Text className={cost({ className: 'bg-yellow text-baseSm' })}>Free with an account</Text>
              </th>
              <th scope="col">
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Fluent
                </Heading>
                <Text variant="sm" className="leading-snug">
                  Pro business account
                  <br /> with all features
                </Text>
                <Text className={cost({ className: 'bg-lightBlue-50 text-baseSm' })}>Coming soon</Text>
              </th>
              <th>
                <Heading variant="h4" as="h3" className="font-baseLg font-bold uppercase">
                  Fluent +
                </Heading>
                <Text variant="sm" className="leading-snug">
                  Unlimited Pro business
                  <br /> account with all features
                </Text>
                <Text className={cost({ className: 'bg-lightBlue-50 text-baseSm' })}>Coming soon</Text>
              </th>
            </tr>
          </thead>
          <tbody className={tableBody()}>
            {map(featureCategories, (category) => {
              return (
                <>
                  <tr key={category.id}>
                    <th>
                      <Heading variant="h6" as="h4" className="mt-4 text-lightBlue-600">
                        {category.heading}
                      </Heading>
                    </th>
                    <td aria-label="empty">
                      <VisuallyHidden>Spacer</VisuallyHidden>
                    </td>
                    <td aria-label="empty">
                      <VisuallyHidden>Spacer</VisuallyHidden>
                    </td>
                    <td aria-label="empty">
                      <VisuallyHidden>Spacer</VisuallyHidden>
                    </td>
                    <td aria-label="empty">
                      <VisuallyHidden>Spacer</VisuallyHidden>
                    </td>
                  </tr>
                  {map(category.features, (feature) => {
                    return (
                      <tr key={feature.id}>
                        <th>{feature.heading}</th>
                        <td>{renderFeatureCell(feature.free[0])}</td>
                        <td>{renderFeatureCell(feature.fast[0])}</td>
                        <td>{renderFeatureCell(feature.fluent[0])}</td>
                        <td>{renderFeatureCell(feature.fluentPlus[0])}</td>
                      </tr>
                    );
                  })}
                </>
              );
            })}
          </tbody>
          <tfoot className={tableFoot()}>
            <tr>
              <th aria-label="empty">
                <VisuallyHidden>Spacer</VisuallyHidden>
              </th>
              <td aria-label="empty">
                <VisuallyHidden>Spacer</VisuallyHidden>
              </td>
              <td>
                <Button
                  href={`${SIGN_IN_PAGE_URL}?${CREATE_ACCOUNT_QUERY_STRING}=${SHOULD_CREATE_ACCOUNT}`}
                  variant="primaryLight"
                  className="mt-md"
                >
                  Sign up now for free!
                </Button>
              </td>
              <td>
                <Button variant="secondaryLight" className="mt-md" disabled>
                  Coming soon
                </Button>
              </td>
              <td>
                <Button variant="secondaryLight" className="mt-md" disabled>
                  Coming soon
                </Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="flex items-center p-4 md:hidden">
        <Button
          href={`${SIGN_IN_PAGE_URL}?${CREATE_ACCOUNT_QUERY_STRING}=${SHOULD_CREATE_ACCOUNT}`}
          variant="primaryLight"
          className="w-full"
        >
          Sign up now for free!
        </Button>
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    wrapper: 'mb-8 lg:mb-16',
    tableWrapper:
      'mx-auto w-full max-w-[var(--container-max-width)] max-md:overflow-y-auto lg:px-8 [&_br]:hidden lg:[&_br]:block',
    table: 'relative m-0 w-full table-auto border-collapse border-spacing-0 border-none [&_td]:text-center',
    tableHead:
      '[&_th:first-child]:sticky [&_th:first-child]:left-0 [&_th:first-child]:min-w-14 md:[&_th:first-child]:z-3 md:[&_th:first-child]:before:absolute md:[&_th:first-child]:before:bottom-0 md:[&_th:first-child]:before:right-full md:[&_th:first-child]:before:top-0 md:[&_th:first-child]:before:w-[5000px] md:[&_th:first-child]:before:bg-grey-50 md:[&_th:first-child]:before:content-[""] md:[&_th:last-child]:after:absolute md:[&_th:last-child]:after:bottom-0 md:[&_th:last-child]:after:left-full md:[&_th:last-child]:after:top-0 md:[&_th:last-child]:after:w-[5000px] md:[&_th:last-child]:after:bg-grey-50 md:[&_th:last-child]:after:content-[""] [&_th:nth-child(3)]:bg-[#f2faff] [&_th:nth-child(3)]:before:absolute [&_th:nth-child(3)]:before:bottom-full [&_th:nth-child(3)]:before:left-0 [&_th:nth-child(3)]:before:right-0 [&_th:nth-child(3)]:before:bg-lightBlue-500 [&_th:nth-child(3)]:before:px-2 [&_th:nth-child(3)]:before:py-2 [&_th:nth-child(3)]:before:text-center [&_th:nth-child(3)]:before:text-baseSm [&_th:nth-child(3)]:before:font-semiBold [&_th:nth-child(3)]:before:leading-snug [&_th:nth-child(3)]:before:text-white [&_th:nth-child(3)]:before:content-["Most_popular"] [&_th]:sticky [&_th]:top-0 [&_th]:z-2 [&_th]:min-w-[164px] [&_th]:bg-grey-50 [&_th]:p-6 [&_th]:align-top lg:[&_th]:min-w-none',
    tableBody:
      '[&_td:nth-child(3)]:bg-[#f2faff] [&_td:nth-child(n+4)]:opacity-50 [&_td:nth-child(n+4)]:grayscale [&_td]:border-b [&_td]:border-grey-100 [&_td]:bg-white [&_td]:py-2 [&_td]:text-center [&_td]:align-middle lg:[&_td]:py-3 [&_th]:sticky [&_th]:left-0 [&_th]:z-2 [&_th]:border-b [&_th]:border-grey-100 [&_th]:bg-white [&_th]:py-2 [&_th]:pl-4 [&_th]:text-left [&_th]:font-normal max-md:[&_th]:border-r max-md:[&_th]:border-grey-200 max-md:[&_th]:pr-4 max-md:[&_th]:text-sm max-md:[&_th]:after:absolute max-md:[&_th]:after:inset-y-0 max-md:[&_th]:after:left-full max-md:[&_th]:after:w-2 max-md:[&_th]:after:bg-gradient-to-r max-md:[&_th]:after:from-grey-100 max-md:[&_th]:after:to-transparent lg:[&_th]:py-3 lg:[&_th]:pl-0',
    tableFoot:
      'max-md:hidden md:table-row-group [&_td:nth-child(3)]:bg-[#f2faff] [&_td]:pb-8 [&_th]:sticky [&_th]:left-0 [&_th]:z-2 [&_th]:bg-white [&_th]:pb-8',
    icon: 'flex justify-center',
    cost: 'mb-0 inline-block rounded-default px-2 py-1 font-medium !leading-none',
  },
});

export { PricingTiers };
