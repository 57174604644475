import { useRecoilState, useRecoilValue } from 'recoil';

import { Icon } from '@/components/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/popover';
import { cargoSearchFormOpenState, cargoSearchParamsState } from '@/state/search';
import { getCargoOptionSelectionString } from '@/utils/cargo';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

import { CargoSearchDropdownForm } from '../dropdown-form';

const CargoSearchTrigger = () => {
  const cargoParams = useRecoilValue(cargoSearchParamsState);
  const [isOpen, setIsOpen] = useRecoilState(cargoSearchFormOpenState);

  const hasCargoParam = !!cargoParams?.cargoType;

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);

    if (!open) {
      trackUser.event('Cargo Filter: Open');
    }
  };

  const { string: triggerString, icon: TriggerIcon } = getCargoOptionSelectionString(cargoParams);

  const { triggerButton, displayIcon, actionIcon, iconWrapper, selectionNum } = styles({
    isActive: isOpen,
    hasValue: hasCargoParam,
  });

  return (
    <Popover onOpenChange={handleOpenChange} open={isOpen}>
      <PopoverTrigger asChild>
        <button type="button" className={triggerButton()} id="cargo-search-trigger">
          <span className={iconWrapper()}>
            {hasCargoParam && <div className={selectionNum()}>1</div>}
            <TriggerIcon className={displayIcon()} />
          </span>{' '}
          {triggerString}
          <Icon
            name="down"
            size="base"
            transform={isOpen ? 'rotate(-180deg)' : undefined}
            className={actionIcon({ className: 'mr-0' })}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="max-w-[360px] p-4">
        <CargoSearchDropdownForm />
      </PopoverContent>
    </Popover>
  );
};

const styles = tv({
  slots: {
    iconWrapper: 'relative -ml-1 mr-2 grid size-6 place-items-center rounded-full bg-lightBlue-50',
    displayIcon: 'size-4 text-lightBlue-600',
    actionIcon: 'ml-2 size-4 [&_svg]:fill-text-primary',
    triggerButton:
      'static inline-flex h-[var(--input-height)] cursor-pointer items-center justify-between rounded-md border border-solid border-grey-300 bg-white px-3 text-baseSm shadow-softer hover:border-grey-400 disabled:cursor-default disabled:opacity-60 disabled:hover:border-grey-300',
    selectionNum:
      'absolute -right-1 -top-[3px] z-10 grid h-[14px] min-w-[14px] place-items-center rounded-full border border-white bg-lightBlue-500 px-1 text-[8px] text-white',
  },
  variants: {
    isActive: {
      true: {
        triggerButton: '!border-grey-700',
      },
    },
    hasValue: {
      true: {
        iconWrapper: '',
      },
    },
  },
});

export { CargoSearchTrigger };
