import {
  CARGO_TYPE_CONTAINER,
  CARGO_TYPE_ITEM,
  UNIT_DISTANCE_CM,
  UNIT_VOLUME_CBM,
  UNIT_WEIGHT_KG,
} from '@/lib/constants';
import { CargoOption } from '@/types';

export const CARGO_SEARCH_FORM_FIELDS = {
  CARGO_TYPE: 'cargoType',
  CONTAINER_SIZE: 'containerSize',
  TOTAL_WEIGHT: 'totalWeight',
} as const;

export const containerDefaults: CargoOption = {
  cargoType: CARGO_TYPE_CONTAINER,
  quantity: 1,
  containerSize: '20',
  weight: undefined,
};

export const looseItemDefaults: CargoOption = {
  cargoType: CARGO_TYPE_ITEM,
  quantity: 1,
  containerSize: undefined,
  weight: { value: 100, unit: UNIT_WEIGHT_KG },
  volume: { value: 10, unit: UNIT_VOLUME_CBM },
  maxHeight: { value: 120, unit: UNIT_DISTANCE_CM },
};
