import { Link } from '@/components/link';

export interface PaginationItemProps {
  pageNumber: number;
  label?: string;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  getHref?: (page: number) => string;
  handlePageChange: (page: number) => void;
}

const PaginationItem = ({
  pageNumber,
  label,
  icon,
  className,
  disabled,
  getHref,
  handlePageChange,
}: PaginationItemProps) => {
  const content = icon || pageNumber;

  if (getHref) {
    return (
      <Link
        href={getHref(pageNumber)}
        onClick={() => handlePageChange(pageNumber)}
        className={className}
        aria-label={label}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      type="button"
      onClick={() => handlePageChange(pageNumber)}
      disabled={disabled}
      className={className}
      aria-label={label}
    >
      {content}
    </button>
  );
};

export { PaginationItem };
