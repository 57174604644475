import { motion } from 'framer-motion';

import { Text } from '@/components/text';
import { tv } from '@/utils/styles';

export interface LoadingSpinnerProps {
  isVisible: boolean;
  size?: string;
  text?: string;
  className?: string;
  top?: string | number;
}

const spinnerWrapperAnimationVariants = {
  hidden: {
    opacity: 0,
    scale: 0.7,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.6,
      duration: 0.3,
    },
  },
};

const textWrapperAnimationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const firstTextAnimationVariants = {
  hidden: { opacity: 0, x: '-40%' },
  visible: { opacity: 1, x: '-50%' },
};

const secondTextAnimationVariants = {
  hidden: { opacity: 0, x: '-70%' },
  visible: { opacity: 1, x: '-50%' },
};

export const LoadingSpinner = ({
  size = '4rem',
  isVisible = false,
  text,
  top = '50%',
  className,
}: LoadingSpinnerProps) => {
  const { textTopLine, textBottomLine, textWrapper, base, spinnerBackground, spinnerPath, innerText } = styles();

  return (
    <div className={base({ className })} style={{ top }}>
      <motion.div
        initial="hidden"
        animate={isVisible ? 'visible' : 'hidden'}
        variants={spinnerWrapperAnimationVariants}
      >
        <svg viewBox="0 0 50 50" className="animate-spin" width={size} height={size}>
          <circle className={spinnerBackground()} cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
          <circle className={spinnerPath()} cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
        </svg>
      </motion.div>
      {Boolean(text) && (
        <motion.div
          initial="hidden"
          animate={isVisible ? 'visible' : 'hidden'}
          transition={{ duration: 0.4, delay: isVisible ? 3 : 0 }}
          variants={textWrapperAnimationVariants}
        >
          <div className={textWrapper()}>
            <Text variant="label" className={textTopLine()}>
              <motion.span
                className={innerText()}
                initial="visible"
                animate={isVisible ? 'hidden' : 'visible'}
                transition={{ duration: 0.3, delay: isVisible ? 7 : 1 }}
                variants={firstTextAnimationVariants}
              >
                Fetching
              </motion.span>
              <motion.span
                className={innerText()}
                initial="hidden"
                animate={isVisible ? 'visible' : 'hidden'}
                variants={secondTextAnimationVariants}
                transition={{ duration: 0.3, delay: isVisible ? 7 : 1 }}
              >
                Compiling
              </motion.span>
            </Text>
            <Text variant="label" className={textBottomLine()}>
              {text}
            </Text>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'pointer-events-none absolute left-1/2 z-10 inline-flex -translate-x-1/2 -translate-y-1/2',
    textWrapper:
      'absolute -bottom-6 left-1/2 block w-[98px] -translate-x-1/2 text-center font-semibold text-grey-400 transition-opacity duration-300 ease-in',
    textTopLine: 'relative m-0 text-center font-semibold text-grey-400',
    textBottomLine: 'm-0 translate-y-[12px] text-center font-bold text-grey-400',
    innerText: 'absolute left-1/2 -translate-x-1/2',
    spinnerBackground: 'stroke-grey-200 [stroke-linecap:round]',
    spinnerPath: 'animate-dash stroke-lightBlue-500 [stroke-linecap:round]',
  },
});
