import { yupResolver } from '@hookform/resolvers/yup';
import * as Popover from '@radix-ui/react-popover';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import posthog from 'posthog-js';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from '@/components/button';
import { TextInput } from '@/components/inputs';
import { Link } from '@/components/link';
import { Text } from '@/components/text';
import { useNotifications } from '@/hooks/use-notifications';
import { fluentProvider } from '@/lib/api/providers/fluent';
import { NEXT_AUTH_STATUSES, USER_MESSAGES } from '@/lib/constants';
import { marketingPages } from '@/lib/constants/marketing-site';
import { tv } from '@/utils/styles';
import { trackUser } from '@/utils/tracking';

interface Props {
  session: Session;
  isOpen: boolean;
  setFeedbackIsOpen: (isOpen: boolean) => void;
  status?: (typeof NEXT_AUTH_STATUSES)[keyof typeof NEXT_AUTH_STATUSES];
}

export interface FeedbackFormInterface {
  email: string;
  feedback: string;
}

export const feedbackValidationSchema = yup.object().shape({
  feedback: yup.string().required('Enter a message'),
  email: yup.string().email('Invalid email address'),
});

const FeedbackPopover = ({ isOpen, session, status, setFeedbackIsOpen }: Props) => {
  const { addNotification } = useNotifications();
  const router = useRouter();
  const user = session?.user;
  // eslint-disable-next-line no-underscore-dangle
  const postHogUserId = posthog?.__loaded ? posthog?.get_distinct_id() : 'unknown';

  const isSignedIn = status === NEXT_AUTH_STATUSES.AUTHENTICATED;

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FeedbackFormInterface>({
    defaultValues: {},
    resolver: yupResolver(feedbackValidationSchema),
  });

  const { popoverContent, form, formActions, footerText, emailListItem } = styles({ isSignedIn });

  const handleFeedbackSubmit = async ({ feedback, email }: FeedbackFormInterface) => {
    try {
      await fluentProvider.sendFeedbackEmail({
        feedback,
        email,
        meta: {
          url: router.asPath,
          postHogUserId,
        },
      });

      addNotification({
        heading: 'Success!',
        description: 'Feedback sent',
        variant: 'success',
      });

      setFeedbackIsOpen(false);

      trackUser.event('Submit feedback');

      // Reset the form incase the user wants to forward another quote
      reset();
    } catch (error: any) {
      addNotification({
        heading: 'Error sending feedback',
        description: `There was an issue sending the feedback. ${USER_MESSAGES.TRY_AGAIN}`,
        variant: 'error',
      });
    }
  };

  return (
    <Popover.Content sideOffset={10} forceMount asChild className={popoverContent()}>
      <motion.div
        initial="hidden"
        exit="hidden"
        animate={isOpen ? 'open' : 'hidden'}
        variants={{
          open: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0.8 },
        }}
      >
        <form onSubmit={handleSubmit(handleFeedbackSubmit)} className={form()}>
          <ol>
            <li>
              <Controller
                control={control}
                name="feedback"
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label="Your feedback"
                    placeholder="Ideas on how to improve this page, feedback on our results or data inaccuracies."
                    errors={errors}
                    rows="5"
                    isTextArea
                    disabled={isSubmitting}
                  />
                )}
              />
            </li>
            <li className={emailListItem()}>
              <Controller
                control={control}
                name="email"
                defaultValue={user?.email || ''}
                render={({ field }) => (
                  <TextInput {...field} label="Email address" errors={errors} isOptional disabled={isSubmitting} />
                )}
              />
            </li>
            <li className={formActions()}>
              <Button
                type="button"
                disabled={isSubmitting}
                size="small"
                variant="secondaryLight"
                onClick={() => setFeedbackIsOpen(false)}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting} size="small">
                Send feedback
              </Button>
            </li>
          </ol>
          <Text className={footerText()}>
            If you have a technical issue, need support, or want to ask a question, please use our{' '}
            <Link href={marketingPages.contact.url}>contact form</Link>.
          </Text>
        </form>
      </motion.div>
    </Popover.Content>
  );
};

const styles = tv({
  slots: {
    popoverContent:
      'w-full rounded bg-grey-100 text-grey-800 shadow-md [transform-origin:var(--radix-popover-content-transform-origin)] md:w-[400px] md:bg-white',
    form: 'mt-12 p-md md:mt-0 [&_li]:mb-4 last:[&_li]:mb-0 [&_ol]:mb-4',
    formActions: 'mb-4 flex justify-between',
    footerText: 'm-0 text-sm [&_a]:text-lightBlue-600',
    emailListItem: '',
  },
  variants: {
    isSignedIn: {
      true: {
        popoverContent: 'mt-1 md:mt-0',
        emailListItem: 'hidden',
      },
      false: {
        popoverContent: 'mt-12',
        emailListItem: 'block',
      },
    },
  },
});

export { FeedbackPopover };
