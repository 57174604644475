import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import { ELLIPSIS_DOTS, usePagination } from '@/hooks/use-pagination';
import { tv } from '@/utils/styles';

import { PaginationItem } from './item';

export interface PaginationProps {
  perPage?: number;
  handlePageChange: (itemPageNumber: number) => void;
  pageNumber: number;
  totalCount: number;
  getHref?: (page: number) => string; // If provided, the pagination items will be links instead of buttons
}

const Pagination = ({ perPage = 10, handlePageChange, pageNumber = 1, totalCount, getHref }: PaginationProps) => {
  const totalPageCount = Math.ceil(totalCount / perPage);

  const paginationRange = usePagination({
    pageNumber,
    totalCount,
    siblingCount: 1,
    perPage,
  });

  const { base, nav, paginationItem, paginationEllipsis, paginationList, paginationPrevNext } = styles({
    isSinglePage: totalPageCount < 2,
  });

  return (
    <div className={base()}>
      <nav className={nav()}>
        <PaginationItem
          pageNumber={pageNumber - 1}
          label="Previous page"
          icon={<ChevronLeftIcon size={22} className="text-grey-500" />}
          className={paginationPrevNext({ isDisabled: pageNumber === 1 })}
          disabled={pageNumber === 1}
          getHref={getHref}
          handlePageChange={handlePageChange}
        />

        <ol className={paginationList()}>
          {paginationRange.map((itemPageNumber, index) => {
            if (itemPageNumber === ELLIPSIS_DOTS) {
              return (
                <li key={`ellipsis-${index}`}>
                  <span className={paginationEllipsis()}>&hellip;</span>
                </li>
              );
            }

            const page = Number(itemPageNumber);
            return (
              <li key={`listing-page-${itemPageNumber}`}>
                <PaginationItem
                  pageNumber={page}
                  className={paginationItem({ isActive: page === pageNumber })}
                  getHref={getHref}
                  handlePageChange={handlePageChange}
                />
              </li>
            );
          })}
        </ol>

        <PaginationItem
          pageNumber={pageNumber + 1}
          label="Next page"
          icon={<ChevronRightIcon size={22} className="text-grey-500" />}
          className={paginationPrevNext({ isDisabled: pageNumber === totalPageCount })}
          disabled={pageNumber === totalPageCount}
          getHref={getHref}
          handlePageChange={handlePageChange}
        />
      </nav>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex items-center justify-center',
    nav: 'mx-auto mt-8 inline-flex content-center items-center p-1',
    paginationList: 'flex items-center',
    paginationItem:
      'mx-0.5 inline-flex min-w-8 cursor-pointer justify-center rounded-default border-none bg-white p-2 text-center font-medium leading-none text-text-primary hover:bg-lightBlue-500 hover:text-white',
    paginationEllipsis: 'mx-2 border-none bg-transparent p-2 font-medium text-grey-500',
    paginationPrevNext:
      'mx-0.5 flex size-8 cursor-pointer items-center justify-center rounded-default border-none bg-transparent font-medium text-lightBlue-600 hover:bg-grey-200 hover:text-lightBlue-600',
  },
  variants: {
    isSinglePage: {
      true: {
        nav: 'hidden',
      },
    },
    isActive: {
      true: {
        paginationItem: 'cursor-default bg-lightBlue-500 text-white',
      },
    },
    isDisabled: {
      true: {
        paginationPrevNext: 'pointer-events-none cursor-default text-lightBlue-600 opacity-50',
      },
    },
  },
});

export { Pagination };
