import { themeGet } from '@styled-system/theme-get';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { system } from 'styled-system';

const rems = system({
  rems: {
    property: 'fontSize',
    scale: 'rems',
  },
});

const BaseStyles = createGlobalStyle`
  ${normalize};

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  /*
   * only suppress the focus ring once what-input has successfully started
  */

  /* suppress focus ring on form controls for mouse users */
  input[data-whatintent='mouse'] *:focus ,
  textarea[data-whatintent='mouse'] *:focus {
    outline: none;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    padding: 0;
  }

  strong {
    font-weight: ${themeGet('fontWeights.semiBold')};
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  figure {
    margin: 0;
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }

  p {
    margin-top: 0;
    margin-bottom: ${themeGet('space.base')};
  }

  svg {
    display: inline-block;
    vertical-align: top;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  legend {
    padding: 0;
    margin: 0;
  }

  ${'' /* Generate base responsive REM values for spacing */}
  html {
    ${({ theme }) => rems({ rems: theme.rems })};
    scroll-behavior: smooth;
    // @NOTE: overflow-x: hidden; was originally added to fix an issue with the
    // filters side nav pushing elements off screen (and sliders but non used currently)
    // the filters side nav doesn't seem to have this issue anymore and this CSS rule
    // was interfering with how certain Radix components (Dialog, Alert) remove the
    // scrollbar to fix the screen. Removed for now but leaving this note here for
    // posterities sake.
}

`;

export { BaseStyles };
